import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig() || {};

const WWW_URL = process.env.NEXT_PUBLIC_WWW_URL;

const BASE_PATH = publicRuntimeConfig?.basePath || "/";

const ADMIN_CONTACT =
  process.env.NEXT_PUBLIC_ADMIN_CONTACT || "contact@daishohq.com";

const ALLOWED_USERS = process.env.NEXT_PUBLIC_RESTRICTED_USERS?.split(",")
  .map((r) => r.split(" "))
  .flat();

const LOGOUT_ON_SESSION_CLOSE = ["true", "True", true].includes(
  process.env.NEXT_PUBLIC_LOGOUT_ON_SESSION_CLOSE === undefined
    ? true
    : process.env.NEXT_PUBLIC_LOGOUT_ON_SESSION_CLOSE
);

const IDENTITY_PROVIDER = process.env.NEXT_PUBLIC_IDENTITY_PROVIDER as
  | "daisho-jwt"
  | "firebase"
  | "azure-ad";
const SIGN_IN_ALLOWED = [true, "true"].includes(
  process.env.NEXT_PUBLIC_ALLOW_SIGNIN || false
);

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
const FIREBASE_DB_URL = process.env.NEXT_PUBLIC_FIREBASE_DB_URL;
const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_ID = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_ID;
const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID;

const UPGRADE_URL = process.env.NEXT_PUBLIC_UPGRADE_URL;

const API_BASE_URL = process.env.NEXT_PUBLIC_DAISHO_API_URL;
const AUTH_API_URL = process.env.NEXT_PUBLIC_AUTH_API_URL;

const SENTRY_DSN = process.env.NEXT_PUBLIC_DAISHO_SENTRY_DSN;

export const BEACON_IDS = {
  landing: "c5b584b9-507a-498d-be08-c78245f70bd7",
  "data-files": "7b8bc203-d4c4-404f-9396-1d22ea9e09a7",
  workspaces: "e58d64d3-98cc-41a2-a9c6-875dfc6220f6",
};

const AZURE_AD_APPLICATION_ID = process.env.NEXT_PUBLIC_AZURE_AD_APPLICATION_ID;
const AZURE_AD_AUTHORITY_URL = process.env.NEXT_PUBLIC_AZURE_AD_AUTHORITY_URL;
const AZURE_AD_DIRECTORY_ID = process.env.NEXT_PUBLIC_AZURE_AD_DIRECTORY_ID;
const AZURE_AD_APP_SECRET = process.env.NEXT_PUBLIC_AZURE_AD_APP_SECRET;

const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV || "on-premise";
const ALLOW_M2M_APPS =
  DEPLOY_ENV === "daisho-cloud"
    ? true
    : ["true", "True", true].includes(
        process.env.NEXT_PUBLIC_ALLOW_M2M_APPS === undefined
          ? false
          : process.env.NEXT_PUBLIC_ALLOW_M2M_APPS
      );

const config = {
  DEV: process.env.NODE_ENV === "development",
  ALLOWED_USERS,
  BASE_PATH,
  ADMIN_CONTACT,
  DEPLOY_ENV,
  ALLOW_M2M_APPS,
  LOGOUT_ON_SESSION_CLOSE,
  IDENTITY_PROVIDER,
  SIGN_IN_ALLOWED,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DB_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  AZURE_AD_APPLICATION_ID,
  AZURE_AD_AUTHORITY_URL,
  AZURE_AD_DIRECTORY_ID,
  AZURE_AD_APP_SECRET,
  UPGRADE_URL,
  API_BASE_URL,
  AUTH_API_URL,
  SENTRY_DSN,
  WWW_URL,
};

export default config;
