import { useCallback } from "react";

import { Project, Api } from "interfaces";
import { apiUrls as urls } from "config";
import { useGetFetcher, useCompute } from "api";

import { useGetCleanupSpec } from "modules/data-files/api";

export interface CreateProps {
  flow: string;
  data_files: { [key: string]: string | string[] };
  meta_data?: { [key: string]: unknown };
}

type RetType =
  | { success: true; project: Project }
  | { success: false; error: Api.ApiError };

export const useCreateProject = () => {
  const fetcher = useGetFetcher<Project>();

  const createProject = useCallback(
    async (spec: CreateProps): Promise<RetType> => {
      const url = urls.projects;
      try {
        const resp = await fetcher({
          url,
          method: "POST",
          data: spec,
        });
        return { success: true, project: resp };
      } catch (e) {
        return { success: false, error: e as Api.ApiError };
      }
    },
    [fetcher]
  );

  return createProject;
};

export interface DataCreateProps {
  source_data: string;
  create_type: string;
  create_spec: { [key: string]: any };
  name: string;
}

export interface WorkspaceCreateProps {
  key: string;
  meta_data?: { [key: string]: any };
  annotations?: { [key: string]: any };
  recipe: string;
  cleanup_spec?: { [fileId: string]: ReturnType<typeof useGetCleanupSpec> };
  input_data: { [key: string]: string[] };
  variable_mapping: { [key: string]: string[] };
  model_params: {
    [key: string]: string | number | boolean | string[] | number[];
  };
}

export const useCreateProjectWithDataAndWorkspace = () => {
  const fetcher = useGetFetcher<Project>();
  const compute = useCompute(urls.createProjectWithWorkspace, true);
  const create = useCallback(
    async ({
      projectSpec,
      dataSpec,
      workspaceSpec,
    }: {
      projectSpec: CreateProps;
      dataSpec?: DataCreateProps;
      workspaceSpec?: WorkspaceCreateProps;
    }) => {
      if (!dataSpec && !workspaceSpec) {
        const url = urls.projects;
        try {
          const resp = await fetcher({
            url,
            method: "POST",
            data: projectSpec,
          });
          return { success: true, project: resp };
        } catch (e) {
          return { success: false, error: e as Api.ApiError };
        }
      }
      try {
        let data = { "project-spec": projectSpec };
        if (dataSpec) {
          data["data-spec"] = dataSpec;
        }
        if (workspaceSpec) {
          data["workspace-spec"] = workspaceSpec;
        }
        const resp = await compute({ data });
        return { success: true, project: resp };
      } catch (e) {
        return { success: false, error: e as Api.ApiError };
      }
    },
    [fetcher]
  );

  return create;
};
