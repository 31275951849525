import { useEffect } from "react";

import Router from "next/router";

import { ToastContainer, toast } from "react-toastify";

import { useGetUserDetails } from "auth/auth-state";

import Header from "./Header";
import Footer from "./Footer";

const {
  // This write key is associated with https://segment.com/nextjs-example/sources/nextjs.
  NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY,
} = process.env;

if (NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY) {
  // Track client-side page views with Segment
  Router.events.on("routeChangeComplete", (url) => {
    // @ts-ignore
    window.analytics.page(url);
  });
}

const AppShell: React.FC = ({ children }) => {
  const user = useGetUserDetails();
  const email = user?.email;

  useEffect(() => {
    if (NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY && email) {
      // @ts-ignore
      window.analytics.identify(email);
    }
  }, [email]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex-grow">
        <div className="px-12 mt-2 pt-2">{children}</div>
      </main>
      <ToastContainer
        position={toast.POSITION.BOTTOM_CENTER}
        newestOnTop
        limit={3}
        closeButton={true}
        autoClose={10000}
        className="sm:w-full md:w-1/2"
      />
      <Footer />
    </div>
  );
};

export default AppShell;
