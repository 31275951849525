import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Api } from "interfaces";
import { useFetchTasks } from "modules/notifications/api";
import {
  useGetTaskList,
  useGetUnseenTasks,
  useUpdateTaskListFromServer,
  useGetTaskSeen,
} from "modules/notifications/store";
import Message from "modules/notifications/Message";

import DropDown from "./Dropdown";
import NotificationIcon from "./Notification-Icon";

interface Task extends Api.TaskStatus, Api.Task {}

const TASK_TYPES_TO_PUBLISH = [
  "projects/add-workspace",
  "build-model",
  "model-iterate",
  // "upload-data-file",
];

const Toast = ({ task }: { task: Task }) => {
  const [waiting, setWaiting] = useState(true);

  const taskSeen = useGetTaskSeen(task.job_id);

  useEffect(() => {
    if (taskSeen) {
      return;
    }
    const timeElapsed =
      (Date.now() - new Date(task.time_stamp).valueOf()) / 1000;

    if (timeElapsed > 3600 * 34) {
      return;
    }
    if (waiting === false) {
      if (!toast.isActive(task.job_id)) {
        toast(<Message task={task} mode="toast" />, {
          toastId: task.job_id,
        });
      }
    } else {
      setTimeout(() => setWaiting(false), 1000);
    }
  }, [task, taskSeen, waiting]);

  return null;
};

const Notifications = () => {
  const update = useUpdateTaskListFromServer();
  useEffect(() => {
    update();
  }, []);

  useFetchTasks();
  const tasks = useGetTaskList();
  const tasksToPublish = tasks.filter((t) =>
    TASK_TYPES_TO_PUBLISH.includes(t.task_type)
  );
  const unseenTasks = useGetUnseenTasks()
    .map((t) => tasksToPublish.find((tp) => tp.job_id === t))
    .filter((t) => t);

  const tasksToNotify = unseenTasks.filter((t) => t.status !== "IN PROGRESS");

  return (
    <>
      {tasksToNotify.map((t) => (
        <Toast key={t.job_id} task={t} />
      ))}
      <DropDown
        title={<NotificationIcon count={unseenTasks.length} />}
        dropdownAtEnd={false}
        size="xl"
      >
        {tasksToPublish.length >= 1 &&
          tasksToPublish
            .sort(
              (a, b) =>
                new Date(b.time_stamp).valueOf() -
                new Date(a.time_stamp).valueOf()
            )
            .map((t) => (
              <div
                key={t.job_id}
                className="block lg:inline-block hover:bg-gray-300 w-full"
              >
                <Message task={t} mode="dropdown" />
              </div>
            ))}
      </DropDown>
    </>
  );
};

export default Notifications;
