import { Api } from "interfaces";
import { apiUrls as urls } from "config";

import { makeFetchRequest } from "api/fetch";

const delay = (t: number) => new Promise((r) => setTimeout(r, t));

export async function fetchTaskStatus<T = unknown>(
  taskId: string,
  fetcher?: typeof makeFetchRequest
) {
  const taskUrl = `${urls.task_status}/${taskId}`;
  fetcher = fetcher || makeFetchRequest;

  let taskStatus = { status: "IN PROGRESS" } as Api.TaskStatus<T>;

  while (taskStatus.status !== "SUCCESS" && taskStatus.status !== "FAILURE") {
    try {
      taskStatus = await fetcher<Api.TaskStatus<T>>({
        url: taskUrl,
      });
      if (taskStatus.status === "FAILURE") {
        return taskStatus;
      }

      if (taskStatus.status === "SUCCESS") {
        // Check if there was a failure
        const { result } = taskStatus;
        if (result?.error) {
          return {
            status: "FAILURE",
            result: {
              completed: false,
              error: true,
              error_message:
                result?.error_message || result?.message || "Unable to compute",
              data: null,
            },
          } as Api.TaskStatus<T>;
        }
      }
      // In progress. Wait for 2 seconds, and check again.
      await delay(2000);
    } catch (e) {
      console.log(e);
      return {
        status: "FAILURE",
        result: {
          completed: false,
          error: true,
          error_message: e?.detail || "Unable to compute",
          data: null,
        },
      } as Api.TaskStatus<T>;
    }
  }

  return taskStatus;
}

export const processError = (
  error:
    | Api.ApiError
    | Api.ComputeStatus["error_message"]
    | Api.TaskStatus["result"]
    | { [key: string]: string[] | string }
): string | string[] | undefined => {
  if (!error) {
    return;
  }
  if (typeof error === "string" || Array.isArray(error)) {
    return error;
  }

  const { detail, non_field_errors } = error as Api.ApiError;

  if (detail || non_field_errors) {
    let errorMessages: string[] = [];
    if (detail) {
      if (typeof detail === "string") {
        errorMessages.push(detail);
      } else {
        Object.keys(detail).forEach((k) => {
          const e = detail[k];
          if (typeof e === "string") {
            errorMessages.push(`${k}: ${e}`);
          } else {
            errorMessages.push(...e.map((s) => `${k}: ${s}`));
          }
        });
      }
    }

    if (non_field_errors) {
      if (typeof non_field_errors === "string") {
        errorMessages.push(non_field_errors);
      } else {
        errorMessages.push(...non_field_errors);
      }
    }

    return errorMessages;
  }

  const { error: errorB, error_message, message } = error as NonNullable<
    Api.TaskStatus["result"]
  >;

  if (errorB === true) {
    return error_message || message || "Unable to Compute";
  }

  return;
};
