const paths = {
  login: "/login",
  signup: "/sign-up",
  changePassword: "/change-password",
  profile: "/profile",

  dataFiles: "/data-files",
  createData: "/data-files/create",
  uploadData: "/data-upload",
  dataMacro: "/data-files/create/macro",

  recipes: "/recipes",
  projects: "/projects",
  createWorkspace: "/create-model",
  workSpaces: "/predictive-models/workspace",
  workspaceListing: "/predictive-models",
  predictiveModels: "/predictive-models",
  modelDetails: "/predictive-models/model",
  modelMacros: "/predictive-models/macros",
  modelListingForWorkspace: "/predictive-models/workspace",

  downloads: "/downloads",

  toc: "/terms",
  privacy: "/privacy",
};

export default paths;
