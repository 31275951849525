import { useCallback } from "react";
import { useQuery, QueryObserverResult, useQueryClient } from "react-query";

import { apiUrls as urls } from "config";

import { Workspaces, DataFile, Api } from "interfaces";
import { useGetFetcher, useFetchPaginatedData, useFetchAllEntities } from "api";
import { useCompute } from "api";
import {
  useShareEntity,
  useRevokeEntityAccessPermissions,
  useDeleteEntity,
  useFetchEntity,
  useFetchMultipleEntities,
} from "api";

export const useFetchModelListing = () =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(urls.predictive_models);

export const useFetchInputCreationChainForModel = (modelID: string) => {
  const fetcher = useGetFetcher();
  const url = `${urls.predictive_models}/${modelID}/${urls.input_creation_chain}`;
  return useQuery<DataFile.CreationChain, string>(url, () => fetcher({ url }));
};

export const useFetchModelListingForWorkspace = (workspaceId: string) =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceId}`
  );

export const useFetchModelListingForMultipleWorkspaces = (
  workspaceIds: string[]
) =>
  useFetchPaginatedData<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceIds.join(",")}`
  );

export const useFetchFullModelListingForWS = (workspaceId: string) =>
  useFetchAllEntities<Workspaces.PredictiveModel>(
    `${urls.predictive_models}?workspace=${workspaceId}`
  );

export const useFetchModel = (
  modelId: string | undefined
): [
  QueryObserverResult<Workspaces.PredictiveModel, Api.ApiError>,
  QueryObserverResult<Workspaces.WorkSpace, Api.ApiError>
] => {
  const modelResp = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceResp = useFetchEntity<Workspaces.WorkSpace>(
    urls.workspaces,
    modelResp.data?.workspace
  );
  return [modelResp, workspaceResp];
};

export const useGetDisplayParamsForModel = (modelId: string | undefined) => {
  const { data: model } = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceId = model?.workspace;
  const url = `${urls.workspaces}/${workspaceId}/display-params`;
  const fetcher = useGetFetcher<Workspaces.DisplayParams>();

  return useQuery<Workspaces.DisplayParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useGetIterateParamsForModel = (modelId: string | undefined) => {
  const { data: model } = useFetchEntity<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelId
  );
  const workspaceId = model?.workspace;
  const url = `${urls.workspaces}/${workspaceId}/iterate-params`;
  const fetcher = useGetFetcher<Workspaces.IterateParams>();

  return useQuery<Workspaces.IterateParams, Api.ApiError>(
    workspaceId ? url : "",
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!workspaceId,
    }
  );
};

export const useFetchMultipleModels = (modelIds: string[]) =>
  useFetchMultipleEntities<Workspaces.PredictiveModel>(
    urls.predictive_models,
    modelIds
  );

export const useGetShareModel = (modelId: string) => {
  const shareEntity = useShareEntity();
  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return shareEntity({
        baseUrl: urls.workspaces,
        entityId: modelId,
        permissions: permissions,
      });
    },
    [urls, shareEntity, modelId]
  );
};

export const useGetRevokeModelAccessPermissions = (modelId: string) => {
  const revokePermissions = useRevokeEntityAccessPermissions();
  return useCallback(
    (permissions: { can_view?: string[]; can_use?: string[] }) => {
      return revokePermissions({
        baseUrl: urls.workspaces,
        entityId: modelId,
        permissions: permissions,
      });
    },
    [urls, revokePermissions, modelId]
  );
};

export const useDeleteModel = (modelId: string, workspaceId?: string) => {
  const baseUrl = workspaceId
    ? `${urls.predictive_models}?workspace=${workspaceId}`
    : urls.predictive_models;

  const deleteData = useDeleteEntity();
  return useCallback(() => {
    return deleteData({
      baseUrl: baseUrl,
      entityId: modelId,
    });
  }, [baseUrl, modelId, deleteData]);
};

export const useDeleteMultipleModels = (modelIds: string[]) => {
  const baseUrl = urls.predictive_models;

  const deleteData = useDeleteEntity();
  return useCallback(() => {
    modelIds.forEach((id) => deleteData({ baseUrl, entityId: id }));
  }, [baseUrl, modelIds, deleteData]);
};

export const useSetupPredict = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/enable-predictions`;
  return useCompute(url, false);
};

export const useSetupAutoLearn = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/enable-auto-learn`;
  return useCompute(url, false);
};

export const useGenerateExport = (modelId: string) => {
  const url = `${urls.predictive_models}/${modelId}/exports`;
  const fetcher = useGetFetcher();

  return useCallback(
    async (params: { [key: string]: any } = {}) => {
      return fetcher({ url, method: "POST", data: params });
    },
    [url, fetcher]
  );
};

export const useFetchPredictionParameters = (modelId: string | undefined) => {
  const url = `${urls.predictive_models}/${modelId}/feature-details-for-predictions`;
  const fetcher = useGetFetcher<Workspaces.ModelPredictionParameters>();
  return useQuery<Workspaces.ModelPredictionParameters, Api.ApiError>(
    url,
    () => fetcher({ url }),
    {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
      enabled: !!modelId,
    }
  );
};

export const usePredictRow = (modelId?: string) => {
  const fetcher = useGetFetcher<Workspaces.PredictionResult>();

  return useCallback(
    async (
      data: { [column: string]: string | number | Date },
      mId?: string
    ) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }
      const url = `${urls.predictive_models}/${modelId}/predict-row`;
      return await fetcher({ url, method: "POST", data: { data } });
    },
    [modelId, fetcher]
  );
};

export const usePredictFile = (modelId?: string) => {
  const fetcher = useGetFetcher();

  return useCallback(
    async (
      files: File[],
      mapping: { [key: string]: string[] },
      mId?: string
    ) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }

      const url = `${urls.predictive_models}/${modelId}/predict-file`;
      return await fetcher({
        url,
        method: "POST",
        data: { mapping },
        files: files,
        timeout: 1000 * 60 * 120,
      });
    },
    [modelId, fetcher]
  );
};

export const useUpdateModel = (modelId?: string) => {
  const url = `${urls.predictive_models}/${modelId}/update-model`;
  const compute = useCompute(url, false);

  return useCallback(
    async (
      files: File[],
      mapping: { [key: string]: string[] },
      mId?: string
    ) => {
      const id = mId || modelId;
      if (!id) {
        throw Error("Model ID not passed!!!");
      }

      const url = `${urls.predictive_models}/${modelId}/update-model`;
      return await compute({
        computeUrl: url,
        data: { mapping },
        files: files,
      });
    },
    [modelId, compute]
  );
};

interface TemplateSpec {
  template: string;
  source_data: { [key: string]: string };
  enable_auto_learn?: boolean;
  enable_predict?: boolean;
}

export const useCreateModelUsingMacro = () => {
  const url = urls.apply_model_macro;
  const fetcher = useGetFetcher<{ request_id: string }>();
  return useCallback(
    async (spec: TemplateSpec) =>
      await fetcher({ url, method: "POST", data: spec }),
    [url, fetcher]
  );
};

export const useGetUpdateModelName = (modelId: string) => {
  const client = useQueryClient();
  const makeFetchRequest = useGetFetcher<DataFile.DataFile>();

  const submit = useCallback(
    async (name: string) => {
      const url = `${urls.predictive_models}/${modelId}`;

      const resp = await makeFetchRequest({
        url,
        method: "PUT",
        data: { name },
      });
      const data = client.getQueryData<Workspaces.PredictiveModel>([
        urls.predictive_models,
        modelId,
      ]);
      client.setQueryData<Workspaces.PredictiveModel>(
        [urls.predictive_models, modelId],
        {
          ...data,
          name,
        }
      );
      client.setQueryData<{ pages: Workspaces.PredictiveModel[][] }>(
        urls.predictive_models,
        (entities) => {
          if (!entities) {
            return { pages: [] };
          }
          const { pages } = entities;
          return {
            ...entities,
            pages: (pages || []).map((l) =>
              l.map((d) => (d.id !== modelId ? d : { ...d, name }))
            ),
          };
        }
      );
      return resp;
    },
    [modelId]
  );

  return submit;
};
