import { useEffect } from "react";
import { Provider } from "jotai";

import Head from "next/head";

import AOS from "aos";
import "../styles/index.css";

import { APIContainer, queryClient } from "api";
import AuthWrapper from "auth/Auth-Wrapper";
import { useResetUserDetails } from "auth/auth-state";

import AppShell from "modules/app-shell";

function MyApp({ Component, pageProps }) {
  const { dehydratedState, ...componentProps } = pageProps;
  const getLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  const onAuthFailure = useResetUserDetails();

  return (
    <>
      <Head>
        <title>Daisho | Super Powers for Data Science</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Provider>
        <APIContainer
          queryClient={queryClient}
          dehydratedState={dehydratedState}
          onAuthFailure={onAuthFailure}
        >
          <AuthWrapper>
            <AppShell>{getLayout(<Component {...componentProps} />)}</AppShell>
          </AuthWrapper>
        </APIContainer>
      </Provider>
    </>
  );
}

export default MyApp;
