import { useQuery, useQueries, QueryObserverResult } from "react-query";

import { Api } from "interfaces";
import { apiUrls as urls } from "config";
import { useCompute } from "api";

interface Params {
  name: string;
  params?: { [key: string]: any };
}

type Error = {
  message:
    | Api.ApiError
    | Api.ComputeStatus["error_message"]
    | Api.TaskStatus["result"];
};

export function useFetchInsight<T = unknown>(modelId: string, props: Params) {
  const url = `${urls.predictive_models}/${modelId}/insights`;
  const compute = useCompute<Params, T>(url, true);
  return useQuery<T, Error>([url, props], () => compute({ data: props }), {
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    retry: false,
    enabled: !!modelId,
  });
}

export function useFetchMultipleInsights<T = unknown>(
  modelId: string,
  props: Params[]
) {
  const url = `${urls.predictive_models}/${modelId}/insights`;
  const compute = useCompute<Params, T>(url, true);

  return useQueries(
    props.map((p) => ({
      queryKey: [url, p],
      queryFn: () => compute({ data: p }),
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      retry: false,
    }))
  ) as QueryObserverResult<T, Error>[];
}

export function useFetchInsightsFromMultipleModels<T = unknown>(
  modelIds: string[],
  props: Params
) {
  const url = `${urls.predictive_models}/multiple-models/insights`;
  const compute = useCompute<Params, T>(url, true);

  return useQueries(
    modelIds.map((m) => ({
      queryKey: [`${urls.predictive_models}/${m}/insights`, props],
      queryFn: () =>
        compute({
          data: props,
          computeUrl: `${urls.predictive_models}/${m}/insights`,
        }),
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      retry: false,
    }))
  ) as QueryObserverResult<T, Error>[];
}
