import config from "./config";

const { AUTH_API_URL, API_BASE_URL } = config;

const urls = {
  login: `${AUTH_API_URL}/jwt/create/`,
  refresh: `${AUTH_API_URL}/jwt/refresh/`,
  logout: `${API_BASE_URL}/logout/`,

  create_jwt: `${AUTH_API_URL}/jwt/create/`,
  refresh_jwt: `${AUTH_API_URL}/jwt/refresh/`,
  destroy_jwt: `${AUTH_API_URL}/jwt/destroy/`,

  api_keys: `${API_BASE_URL}/api-keys`,

  me: `${API_BASE_URL}/me`,
  usage: `${API_BASE_URL}/account/usage`,
  access_plans: `${API_BASE_URL}/plans/access-plans`,
  addons: `${API_BASE_URL}/plans/addons`,

  change_password: `${AUTH_API_URL}/user/set-password/`,

  task: `${API_BASE_URL}/task`,
  task_list: `${API_BASE_URL}/task-list`,
  task_status: `${API_BASE_URL}/task-status`,
  data_task_status: `${API_BASE_URL}/task-status`,

  downloads: `${API_BASE_URL}/exports`,

  projects: `${API_BASE_URL}/projects`,
  createProjectWithWorkspace: `${API_BASE_URL}/projects/create-project-with-workspace`,

  data: `${API_BASE_URL}/data`,
  creation_chain: "creation-chain",
  data_hygiene: "data-hygiene",
  file_column_stats: "file-column-stats",
  file_outlier_summary: "file-outlier-summary",
  data_column_outlier_rows: "column-outlier-rows",
  data_sample: "data_file_sample",
  data_rows: "data-rows",
  data_column_types: "column-types",
  data_column_profile: "column-profile",
  data_column_stats: "column-stats",
  data_filtered_column_details: "filtered-column-details",
  data_y_impact: "y-impact",
  file_univariate_impact: "file-univariate-impact",
  data_compare_columns: "compare-columns",
  tri_variate_distributions: "tri-variate-distributions",
  data_relationship_matrix: "relationship_matrix",
  data_relationship_sort: "relationship_sort",
  data_cross_relationships: "cross-correlations",
  data_time_series_distributions: "time_series_distributions",
  data_time_series_change_points: "time_series_change_points",
  data_time_series_anomalies: "time_series_anomalies",
  data_id_profiles: "id_profiles",
  data_export_id_profiles: "export_tagged_id_profiles",
  data_document_topics: "document_topics",
  data_export_document_topics: "export_tagged_document_topics",
  data_frequent_itemsets: "frequent_itemsets",
  data_association_rules: "association_rules",
  data_group_differences_summary: "group_differences_summary",
  data_group_differences_column_details: "group_differences_column_details",
  data_grouped_distributions: "grouped_distributions",

  upload_data: `${API_BASE_URL}/data/upload-file`,
  apply_data_file_template: `${API_BASE_URL}/data/apply-template`,

  create_data_clean: `${API_BASE_URL}/data/clean-data`,
  create_data_augment: `${API_BASE_URL}/data/augment-data`,

  create_data_filter: `${API_BASE_URL}/data/filter-data`,
  create_data_aggregates: `${API_BASE_URL}/data/grouped-aggregates`,
  create_data_time_series: `${API_BASE_URL}/data/time-series`,

  create_data_join: `${API_BASE_URL}/data/join-data`,
  create_data_stack: `${API_BASE_URL}/data/stack-data`,

  data_file_templates: `${API_BASE_URL}/data-file-templates`,
  predictive_model_templates: `${API_BASE_URL}/predictive-model-templates`,

  recipes: `${API_BASE_URL}/recipes`,
  predictive_models: `${API_BASE_URL}/predictive-models/models`,
  input_creation_chain: "input-creation-chain",
  workspaces: `${API_BASE_URL}/predictive-models/workspaces`,
  apply_model_macro: `${API_BASE_URL}/predictive-models/models/create-from-macro`,
};

export default urls;
